import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import {} from "antd";
import CustomButton from "./CustomButton";

const EditAction = ({ onClick }) => {
  return (
    <>
      <CustomButton
        type="primary"
        ghost={true}
        icon={<DeleteOutlined />}
        onClick={onClick}
        width={32}
        height={32}
        borderRadius={6}
      />
    </>
  );
};

export default EditAction;
