import React from "react";
import { Modal, Flex } from "antd";
import IconSuccessDialog from "../../assets/icon/icon_success_dialog";

const SuccessDialog = ({ isModalOpen }) => {
  return (
    <>
      <Modal
        open={isModalOpen}
        closable={false}
        footer={null}
        centered
        width={337}
        // height={217}
        style={{
          borderRadius: "20px",
          overflow: "hidden",
        }}
      >
        <Flex justify="center" align="center" vertical>
          <div style={{ marginTop: 10 }}>
            <IconSuccessDialog />
          </div>
          <div
            style={{
              fontWeight: 600,
              fontSize: 18,
              marginTop: 10,
            }}
          >
            Success
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: 14,
              marginTop: 10,
              color: "#475467",
            }}
          >
            Successfully saved data.
          </div>
        </Flex>
      </Modal>
    </>
  );
};

export default SuccessDialog;
