import Icon from "@ant-design/icons";

const icon = () => (
  <svg
    width="78"
    height="78"
    viewBox="0 0 78 78"
    fill="none"
  >
    <g clip-path="url(#clip0_7084_13890)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.625 39C14.625 25.5381 25.5381 14.625 39 14.625C52.4619 14.625 63.375 25.5381 63.375 39C63.375 52.4619 52.4619 63.375 39 63.375C25.5381 63.375 14.625 52.4619 14.625 39ZM48.0257 34.4648C48.6276 33.6222 48.4325 32.4511 47.5898 31.8493C46.7472 31.2474 45.5761 31.4425 44.9742 32.2852L36.8857 43.6091L32.8258 39.5492C32.0936 38.8169 30.9064 38.8169 30.1742 39.5492C29.4419 40.2814 29.4419 41.4686 30.1742 42.2008L35.7992 47.8258C36.1889 48.2156 36.7304 48.4141 37.2797 48.3686C37.8289 48.3232 38.3304 48.0383 38.6508 47.5898L48.0257 34.4648Z"
        fill="#000000"
      />
    </g>
    <defs>
      <clipPath id="clip0_7084_13890">
        <rect width="60" height="60" fill="white" transform="translate(9 9)" />
      </clipPath>
    </defs>
  </svg>
);

const Icon_Success_Dialog = (props) => <Icon component={icon} {...props} />;

export default Icon_Success_Dialog;
