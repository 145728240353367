import axios from "axios";
import { notification } from "antd";
import { OAuthService } from "../OAuthService";
import { handleLogin } from "../../auth/AuthService";

export const api = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_BASE_URI,
});

// defining a custom error handler for all APIs
const errorHandler = async (error) => {
  const statusCode = error.response?.status;

  if (error.code === "ERR_CANCELED") {
    notification.error({
      placement: "bottomRight",
      description: "API canceled!",
    });
    return Promise.resolve();
  }

  const originalRequest = error.config;

  if (statusCode === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    try {
      const refreshToken = sessionStorage.getItem("refreshToken");
      const response = await OAuthService.refreshToken(refreshToken);

      sessionStorage.setItem("accessToken", response.accessToken);
      sessionStorage.setItem("refreshToken", response.refreshToken);

      originalRequest.headers.Authorization = `Bearer ${response.accessToken}`;
      return axios(originalRequest);
    } catch (error) {
      // console.error();
      sessionStorage.clear();
      handleLogin();
    }
  }

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
};

api.interceptors.request.use(
  (config) => {
    const accessToken = sessionStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// registering the custom error handler to the
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});
