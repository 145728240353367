import React, { useState, useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Drawer, Space, Select, Flex, Tag, Spin, Form, Input } from "antd";
import {
  CustomButton,
  ConfirmDialog,
  SuccessDialog,
  FailedDialog,
} from "../../../../components";
import { CampaingService } from "../../../../services/CampaignService";

// const { Option } = Select;

const DrawerCallAddress = ({ onClose, open, items, fetchData }) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [isOpenFailed, setIsOpenFailed] = useState(false);
  // const [refCallAddress, setRefCallAddress] = useState([]);

  // useEffect(() => {
  //   if (!refCallAddress) {
  //     fetchRefCallAddress();
  //   }
  // }, [refCallAddress]);

  // const fetchRefCallAddress = async () => {
  //   // let response = await RefFacApi.getAll();
  //   setRefCallAddress(response);
  // };

  useEffect(() => {
    form.resetFields();
  }, [open, onClose]);

  const handleUpdateCaller = async () => {
    try {
      setLoading(true);
      let ids = items.map((item) => item.id);
      let data = {
        ids: ids,
        callerAddress: value,
      };
      await CampaingService.updateCaller(data);
      setTimeout(() => {
        setLoading(false);
        onClose();
        setIsOpenSuccess(true);
        setTimeout(() => {
          setIsOpenSuccess(false);
          fetchData();
        }, 1000);
      }, 1000);
    } catch (error) {
      console.error();
      setTimeout(() => {
        setLoading(false);
        setIsOpenFailed(true);
      }, 1000);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const onFinish = () => {
    setIsOpenConfirm(true);
  };

  return (
    <>
      <Drawer
        title="Call address"
        placement="right"
        width={470}
        closable={false}
        onClose={() => (onClose(), setValue())}
        open={open}
        extra={
          <Space>
            <CloseOutlined onClick={() => (onClose(), setValue())} />
          </Space>
        }
        footer={
          <Flex justify="flex-end">
            <Space>
              <CustomButton
                text="Cancel"
                onClick={() => (onClose(), setValue())}
                width={100}
              />
              <CustomButton
                text="Save"
                type="primary"
                onClick={() => form.submit()}
                width={100}
              />
            </Space>
          </Flex>
        }
      >
        <Spin spinning={loading} fullscreen />
        <p style={{ fontWeight: "bold" }}>Campaign to change</p>
        <div
          style={{
            width: "410px",
            height: "100px",
            borderRadius: "8px",
            border: "1px solid #D0D5DD",
            margin: "20px 0px",
            padding: "5px",
            overflowY: "scroll",
          }}
        >
          <Space wrap>
            {items.map((item) => (
              <Tag color="default">{item.name}</Tag>
            ))}
          </Space>
        </div>

        {/* <Flex justify="flex-end">
          <a>+ Create Caller address</a>
        </Flex> */}
        <p style={{ fontWeight: "bold" }}>Caller address</p>
        <Form
          hideRequiredMark
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name="callerAddress"
            rules={[
              { required: true, message: "Please input phone number!" },
              {
                pattern: /^\+66\d{8,9}$/,
                message: "Invalid",
              },
            ]}
          >
            <Input onChange={handleChange} placeholder="Input phone number" />
            {/* <Select
              value={value}
              onChange={handleChange}
              placeholder="Select"
              allowClear
              style={{ width: "100%" }}
            >
              {refCallAddress?.map((option) => (
                <Option key={option.id} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select> */}
          </Form.Item>
        </Form>
      </Drawer>

      <ConfirmDialog
        isModalOpen={isOpenConfirm}
        onCancel={() => {
          setIsOpenConfirm(false);
        }}
        onConfirm={() => {
          handleUpdateCaller();
          setIsOpenConfirm(false);
        }}
      />

      <SuccessDialog isModalOpen={isOpenSuccess} />

      <FailedDialog
        isModalOpen={isOpenFailed}
        onCancel={() => {
          setIsOpenFailed(false);
        }}
        onConfirm={() => {
          setIsOpenFailed(false);
          handleUpdateCaller();
        }}
      />
    </>
  );
};

export default DrawerCallAddress;
