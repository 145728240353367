import "./CustomHeader.css";
import React, { useState, useEffect } from "react";
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import {
  Flex,
  Divider,
  Row,
  Col,
  Space,
  Layout,
  Select,
  Button,
  Dropdown,
} from "antd";
// import CustomButton from "../Button/CustomButton";
import { handleLogout } from "../../auth/AuthService";
import { UserService } from "../../services/UserService";

const { Header } = Layout;
const { Option } = Select;

const CustomHeader = () => {
  const [data, setData] = useState({});
  const items = [
    {
      key: "1",
      label: <a onClick={() => handleLogout()}>Logout</a>,
      icon: <LogoutOutlined />,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await UserService.userMe();
      setData(response);
      sessionStorage.setItem("userMe", JSON.stringify(response));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Header className="header-container">
        {/* <Flex align="center">
          <CustomButton
            text="Logout"
            type="primary"
            ghost={true}
            onClick={() => handleLogout()}
          />
        </Flex> */}
        {/* <Flex justify="flex-end" align="center"> */}
        <Space>
          {/* <Select
            className="select"
            value={"Collection operation support"}
            size="middle"
            onChange={(value) => ({})}
          >
            <Option>1</Option>
            <Option>2</Option>
          </Select> */}

          {/* <Col className="user-info">
            <p className="text-email">Saichai@gmail.com</p>
            <p className="text-role">Admin</p>
          </Col> */}
          <p className="text-email">{data?.email}</p>

          <Divider type="vertical" className="divider" />
          <Dropdown
            menu={{
              items,
            }}
            placement="bottom"
            trigger={["click"]}
          >
            <Button
              type="primary"
              icon={<DownOutlined style={{ fontSize: "16px" }} />}
              className="button"
            />
          </Dropdown>
          {/* </Flex> */}
        </Space>
      </Header>
    </>
  );
};

export default CustomHeader;
