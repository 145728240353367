import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./helpers/PrivateRoute";
import Callback from "./page/OAuth/Callback";
import NotFound from "./page/NotFound/NotFound";
import Layout from "./page/Layout/Layout";
import CampaignList from "./page/CampaignList/CampaignList";
import ConvertFile from "./page/ConvertFile/ConvertFile"

import Campaign from "./page/Campaign";
import Upload from "./page/UploadFile";
import ContactList from "./page/ContactList";

import Template from "./page/Template/Template";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/oauth/callback" element={<Callback />} />

        <Route path="/template" element={<Template />}></Route>

        <Route
          path="/"
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute> 
          }
        >
          <Route index element={<CampaignList />} />
          <Route path="convert-file" element={<ConvertFile />} />
          <Route path="*" element={<NotFound />} />
          {/* <Route path="upload" element={<Upload />} /> */}
          {/* <Route path="contact-list" element={<ContactList />} /> */}

          {/* <Route path="campaign-list" element={<Campaign />} /> */}


        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
