import React, { useEffect, useState } from "react";
import { Row, Col, Flex, Upload, message, Button, Spin } from "antd";
import { UploadOutlined, DeleteTwoTone } from "@ant-design/icons";
import { FileService } from "../../services/FileService";

const { Dragger } = Upload;

const ConvertFile = () => {
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false);

  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    customRequest({ file, onSuccess, onError }) {
      const validTypes = [
        "text/csv", // .csv
        "application/csv", // .csv
        "text/plain", // .txt
      ];

      const isValidFile = validTypes.includes(file.type);

      if (!isValidFile) {
        message.error(`The file must be a text (.txt) file only.`);
        onError(new Error("Invalid file type"));
        return;
      }

      onSuccess(null, file);
      setSelectedFile(file);
    },
  };

  const uploadFile = async (file) => {
    try {
      setLoading(true);
      let response = await FileService.convertFile(file);
      setTimeout(() => {
        setLoading(false);
        message.success("Convert success.");
        downloadFile(response);
      }, 1000);
    } catch (error) {
      console.error();
      setTimeout(() => {
        setLoading(false);
        message.error("Convert failed.");
      }, 1000);
    }
  };

  const removeFileExtension = (filename) => {
    return filename.replace(/\.[^/.]+$/, "");
  };

  const downloadFile = (file) => {
    const filename = removeFileExtension(selectedFile.name);

    const url = window.URL.createObjectURL(file);

    const link = document.createElement("a");
    link.href = url;
    link.download = `convert_${filename}.zip`;
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);

    setSelectedFile();
  };

  const handleUpload = () => {
    uploadFile(selectedFile);
  };

  const handleDeleteFile = () => {
    setSelectedFile();
  };

  return (
    <>
      <div className="convert-file-container">
        <p
          style={{
            margin: "0px",
            fontSize: "22px",
            fontWeight: 600,
          }}
        >
          Convert File
        </p>
        <div
          style={{
            // padding: 24,
            margin: "20px 0px",
            padding: "10px 30px",
            backgroundColor: "#fff",
            borderRadius: "10px",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            Choose file
          </p>

          <div
            style={{
              width: "450px",
              height: "100px",
            }}
          >
            <Dragger
              {...props}
              style={{
                width: "100%",
                height: "100px",
              }}
            >
              <Spin spinning={loading}>
                {selectedFile ? (
                  <>
                    <p
                      style={{
                        fontSize: "16px",
                        marginRight: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {selectedFile?.name}
                    </p>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Col
                        style={{
                          textAlign: "center",
                          marginRight: "10px",
                        }}
                      >
                        <UploadOutlined
                          style={{
                            fontSize: "30px",
                            // color: "#FF7F07"
                          }}
                        />
                      </Col>
                      <Col
                        style={{
                          margin: "0px 10px",
                        }}
                      >
                        <Row
                          style={{
                            margin: "0px 0px",
                          }}
                        >
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                        </Row>
                        <Row
                          style={{
                            margin: "-15px 0px",
                          }}
                        >
                          <p className="ant-upload-hint">
                            or <a>Browse</a> to choose a file
                          </p>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                )}
              </Spin>
            </Dragger>
          </div>
          <div
            style={{
              height: "50px",
              margin: "10px 0px",
            }}
          >
            <Flex
              justify="flex-start"
              align="center"
              style={{
                height: "100%",
              }}
            >
              <Button
                disabled={selectedFile && !loading ? false : true}
                type="primary"
                style={{
                  marginRight: "20px",
                }}
                onClick={handleUpload}
              >
                Convert
              </Button>

              {selectedFile ? (
                <>
                  <p
                    style={{
                      fontSize: "16px",
                      marginRight: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {selectedFile?.name}
                  </p>
                  <DeleteTwoTone
                    twoToneColor="red"
                    onClick={handleDeleteFile}
                  />
                </>
              ) : (
                <p
                  style={{
                    // fontSize: "",
                    marginRight: "10px",
                    color: "#868686",
                  }}
                >
                  Choose the file to convert.
                </p>
              )}
            </Flex>
          </div>
          <p
            style={{
              color: "red",
              marginTop: "10px",
            }}
          >
            *You can upload one file at a time.
          </p>
        </div>
      </div>
    </>
  );
};

export default ConvertFile;
