import "./Template.css";
import React, { useState, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Flex, Input, Form, Space } from "antd";
import {
  CustomButton,
  EditAction,
  DeleteAction,
  FormItem,
  CustomSelect,
  CustomTable,
  ConfirmDialog,
  SuccessDialog,
  FailedDialog,
  DeleteDialog,
  DiscardDialog,
} from "../../components";

// Service
import { CampaingService } from "../../services/CampaignService";

const { TextArea } = Input;

const Template = () => {
  // Dialog
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalDiscardOpen, setIsModalDiscardOpen] = useState(false);

  // Selcet
  const [selectedValue, setSelectedValue] = useState(null);

  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  // Table
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const getParams = (params) => ({
    // Name: keywords,
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      let params = getParams();
      let response = await CampaingService.search(params);
      setData(response.items);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Caller address",
      dataIndex: "callerAddress",
    },
    {
      title: "Agent Script",
      // dataIndex: "",
      // render: (text, record) => <p>{record.script.name}</p>,
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      // sorter: true,
    },
    {
      title: "Date Modified",
      dataIndex: "dateModified",
      // sorter: true,
    },
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   render: (text, record) => (
    //     <span style={{ whiteSpace: "nowrap" }}>
    //       <Tooltip placement="bottom" title="View / Edit"></Tooltip>
    //     </span>
    //   ),
    // },
  ];

  // Form
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    name: "kiw",
    lastname: "phak",
    message: "",
    phone: "",
    select: "",
    checked: false,
    textarea: "",
  });

  const onFinish = async (values) => {
    console.log("Form values:", values);
  };

  const validator = async (_, value) => {
    if (value && value.length < 5) {
      return Promise.reject("The value should be at least 5 characters long.");
    }
    return Promise.resolve();
  };

  return (
    <>
      <div className="template-container">
        <h2>Template</h2>

        <Flex gap="small" align="flex-start" vertical>
          <h3>Button</h3>
          <Flex gap="small">
            <CustomButton text="Primary" type="primary" />
            <CustomButton text="Default" />
            <CustomButton text="Ghost" type="primary" ghost={true} />
            <CustomButton
              type="primary"
              icon={<SearchOutlined />}
              shape="circle"
            />
            <CustomButton text="Danger" danger={true} />
            <CustomButton text="Danger" type="primary" danger={true} />
            <CustomButton text="text" type="text" />
            <CustomButton text="dashed" type="dashed" />
            <CustomButton text="link" type="link" />
          </Flex>
          <Flex gap="small">
            <CustomButton
              text="Click Me"
              type="primary"
              onClick={() => alert("Button clicked!")}
              width={130}
              height={40}
              borderRadius={30}
            />
            <CustomButton
              text="Search"
              type="primary"
              icon={<SearchOutlined />}
              color="#000"
              padding="0px 30px"
            />
            <CustomButton
              text="Search"
              type="primary"
              icon={<SearchOutlined />}
              iconPosition="end"
              padding="0px 30px"
            />
            <CustomButton text="background" color="#fff" background="#999900" />
            <CustomButton text="Loading" type="primary" loading={true} />
          </Flex>
          <Flex gap="small">
            <CustomButton text="fontSize" fontSize="18px" />
            <CustomButton text="fontWeight" fontSize="18px" fontWeight="bold" />
            <CustomButton
              text="custom"
              color="#fff"
              background="linear-gradient(135deg, #6253e1, #04befe)"
            />
          </Flex>

          <h3>Button Action</h3>
          <Flex gap="small">
            <EditAction onClick={() => alert("Edit Action")} />
            <DeleteAction onClick={() => setIsModalDeleteOpen(true)} />
          </Flex>

          <h3>Input (Form Item)</h3>
          <Form
            hideRequiredMark
            layout="vertical"
            form={form}
            initialValues={formData}
            onFinish={onFinish}
          >
            <FormItem
              name="name"
              label="Name"
              required={true}
              requiredMessage="please Input Name."
              tooltipMessage="This is a required field"
            >
              <Input
                type="text"
                placeholder="Input name."
                style={{ width: "200px" }}
              />
            </FormItem>
            <FormItem name="lastname" label="Last Name">
              <Input
                type="text"
                placeholder="Input last name."
                style={{ width: "300px", borderRadius: "20px" }}
              />
            </FormItem>
            <FormItem
              name="message"
              label="Message"
              tooltipMessage="Message > 5 characters"
              validator={validator}
            >
              <Input placeholder="Input Message." />
            </FormItem>
            <FormItem
              name="phone"
              label="Phone Number"
              required={true}
              requiredMessage="please Input Phone Number."
              pattern={/^\d{10}$/}
              patternMessage="Phone number must be exactly 10 digits long."
            >
              <Input placeholder="Input Phone Number." allowClear />
            </FormItem>
            <FormItem
              name="select"
              label="Option"
              required={true}
              requiredMessage="please select option."
              tooltipMessage="select option."
            >
              <CustomSelect
                placeholder="select option"
                options={options}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </FormItem>
            <FormItem
              name="textarea"
              label="Text Area"
              tooltipMessage="Text Area"
            >
              <TextArea rows={4} placeholder="Text" maxLength={6} />
            </FormItem>
            <CustomButton
              text="Submit"
              type="primary"
              htmlType="submit"
              // onClick={() => form.submit()}
            />
          </Form>

          <h3>Table</h3>
          <Flex gap="small">
            <CustomTable
              columns={columns}
              // fetchData={fetchData}
              // getParams={getParams}
              data={data}
              loading={loading}
              useRowSelection={true}
              onRowSelectionChange={(selectedKeys) => console.log(selectedKeys)}
            />
          </Flex>

          <h3>Confirm Dialog</h3>
          <Flex gap="small">
            <CustomButton
              text="Show Dialog"
              type="primary"
              onClick={() => setIsModalOpen(true)}
              width={130}
              height={40}
            />
            <ConfirmDialog
              isModalOpen={isModalOpen}
              onCancel={() => {
                setIsModalOpen(false);
              }}
              onConfirm={() => {
                alert("OK");
                setIsModalOpen(false);
              }}
            />
          </Flex>

          <h3>Success Dialog</h3>
          <Flex gap="small">
            <CustomButton
              text="Show Dialog"
              type="primary"
              onClick={() => setIsModalSuccessOpen(true)}
              width={130}
              height={40}
            />
            <SuccessDialog isModalOpen={isModalSuccessOpen} />
          </Flex>

          <h3>Failed Dialog</h3>
          <Flex gap="small">
            <CustomButton
              text="Show Dialog"
              type="primary"
              onClick={() => setIsModalFailedOpen(true)}
              width={130}
              height={40}
            />
            <FailedDialog
              isModalOpen={isModalFailedOpen}
              onCancel={() => {
                setIsModalFailedOpen(false);
              }}
              onConfirm={() => {
                alert("Try again");
              }}
            />
          </Flex>

          <h3>Delete Dialog</h3>
          <Flex gap="small">
            <CustomButton
              text="Show Dialog"
              type="primary"
              onClick={() => setIsModalDeleteOpen(true)}
              width={130}
              height={40}
            />
            <DeleteDialog
              isModalOpen={isModalDeleteOpen}
              closable={false}
              title="Confirm Delete"
              cancelText="Cancel"
              confirmText="Delete"
              onCancel={() => {
                setIsModalDeleteOpen(false);
              }}
              onConfirm={() => {
                alert("OK");
              }}
              description="Are you sure to delete ?"
            />
          </Flex>

          <h3>Discard Dialog</h3>
          <Flex gap="small">
            <CustomButton
              text="Show Dialog"
              type="primary"
              onClick={() => setIsModalDiscardOpen(true)}
              width={130}
              height={40}
            />
            <DiscardDialog
              isModalOpen={isModalDiscardOpen}
              closable={false}
              title="Discard changes"
              cancelText="Cancel"
              confirmText="Confirm"
              onCancel={() => {
                setIsModalDiscardOpen(false);
              }}
              onConfirm={() => {
                alert("OK");
              }}
              description="Changes will not be saved. Do you want to proceed ?"
            />
          </Flex>
        </Flex>
      </div>
    </>
  );
};

export default Template;
