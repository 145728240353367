import { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { handleLogin } from "../../auth/AuthService";
import { OAuthService } from "../../services/OAuthService";
import { UserService } from "../../services/UserService";

const Callback = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");
  const prevCodeRef = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (code) {
      if (prevCodeRef.current !== code) {
        fetchData();
        prevCodeRef.current = code;
      }
    } else {
      handleLogin();
    }
  }, [code]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await OAuthService.codeAuthorization(code);
      setData(response);

      sessionStorage.setItem("accessToken", response.accessToken);
      sessionStorage.setItem("refreshToken", response.refreshToken);

      setLoading(false);
      navigate("/");
    } catch (error) {
      setLoading(false);
      console.error(error);
      handleLogin();
    }
  };

  return (
    <>
      <Spin spinning={loading} fullscreen />
    </>
  );
};

export default Callback;
