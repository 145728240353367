import Icon from "@ant-design/icons";

const icon = () => (
  <svg
    width="50"
    height="49"
    viewBox="0 0 50 49"
    fill="none"
  >
    <path
      d="M2.5 14C2.5 9.79961 2.5 7.69941 3.31745 6.09507C4.0365 4.68386 5.18386 3.5365 6.59507 2.81745C8.19941 2 10.2996 2 14.5 2H31.6863C32.9092 2 33.5207 2 34.0962 2.13815C34.6063 2.26063 35.0941 2.46266 35.5414 2.7368C36.046 3.04601 36.4784 3.47839 37.3431 4.34315L45.1569 12.1569C46.0216 13.0216 46.454 13.454 46.7632 13.9586C47.0373 14.4059 47.2394 14.8937 47.3619 15.4038C47.5 15.9793 47.5 16.5908 47.5 17.8137V35C47.5 39.2004 47.5 41.3006 46.6825 42.9049C45.9635 44.3161 44.8161 45.4635 43.4049 46.1825C41.8006 47 39.7004 47 35.5 47H14.5C10.2996 47 8.19941 47 6.59507 46.1825C5.18386 45.4635 4.0365 44.3161 3.31745 42.9049C2.5 41.3006 2.5 39.2004 2.5 35V14Z"
      fill="#1C4289"
    />
    <path
      d="M12.5 2V10.5C12.5 11.9001 12.5 12.6002 12.7725 13.135C13.0122 13.6054 13.3946 13.9878 13.865 14.2275C14.3998 14.5 15.0999 14.5 16.5 14.5H33.5C34.9001 14.5 35.6002 14.5 36.135 14.2275C36.6054 13.9878 36.9878 13.6054 37.2275 13.135C37.5 12.6002 37.5 11.9001 37.5 10.5V4.5M37.5 47V31C37.5 29.5999 37.5 28.8998 37.2275 28.365C36.9878 27.8946 36.6054 27.5122 36.135 27.2725C35.6002 27 34.9001 27 33.5 27H16.5C15.0999 27 14.3998 27 13.865 27.2725C13.3946 27.5122 13.0122 27.8946 12.7725 28.365C12.5 28.8998 12.5 29.5999 12.5 31V47M47.5 17.8137V35C47.5 39.2004 47.5 41.3006 46.6825 42.9049C45.9635 44.3161 44.8161 45.4635 43.4049 46.1825C41.8006 47 39.7004 47 35.5 47H14.5C10.2996 47 8.19941 47 6.59507 46.1825C5.18386 45.4635 4.0365 44.3161 3.31745 42.9049C2.5 41.3006 2.5 39.2004 2.5 35V14C2.5 9.79961 2.5 7.69941 3.31745 6.09507C4.0365 4.68386 5.18386 3.5365 6.59507 2.81745C8.19941 2 10.2996 2 14.5 2H31.6863C32.9092 2 33.5207 2 34.0962 2.13815C34.6063 2.26063 35.0941 2.46266 35.5414 2.7368C36.046 3.04601 36.4784 3.47839 37.3431 4.34315L45.1569 12.1569C46.0216 13.0216 46.454 13.454 46.7632 13.9586C47.0373 14.4059 47.2394 14.8937 47.3619 15.4038C47.5 15.9793 47.5 16.5908 47.5 17.8137Z"
      stroke="white"
      stroke-width="3.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const Icon_Save_Blue = (props) => <Icon component={icon} {...props} />;

export default Icon_Save_Blue;
