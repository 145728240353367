import React from "react";
import { Button, Modal, Flex } from "antd";
import IconFailedDialog from "../../assets/icon/icon_failed_dialog";
import CustomButton from "../../components/Button/CustomButton";

const FailedDialog = ({ isModalOpen, onCancel, onConfirm }) => {
  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={onCancel}
        closable={false}
        footer={null}
        centered
        width={337}
        // height={217}
        style={{
          borderRadius: "20px",
          overflow: "hidden",
        }}
      >
        <Flex justify="center" align="center" vertical>
          <IconFailedDialog />
          <div
            style={{
              fontWeight: 600,
              fontSize: 18,
              // marginTop: 10,
            }}
          >
            Failed
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: 14,
              marginTop: 10,
              color: "#475467",
            }}
          >
            Something went wrong. Please try again.
          </div>

          <CustomButton
            text="Try Again"
            type="primary"
            onClick={onConfirm}
            width={100}
            height={32}
            margin="20px 0px 0px 0px"
          />
        </Flex>
      </Modal>
    </>
  );
};

export default FailedDialog;
