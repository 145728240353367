import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const OAuthService = {
  codeAuthorization: async function (code, cancel = false) {
    const response = await api.request({
      url: `/oauth/code-authorization`,
      method: `POST`,
      data: {
        code: code,
      },
      signal: cancel ? cancelApiObject[this.updateCaller.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },

  refreshToken: async function (token, cancel = false) {
    const response = await api.request({
      url: `/oauth/refresh-token`,
      method: `POST`,
      data: {
        refreshToken: token,
      },
      signal: cancel ? cancelApiObject[this.refreshToken.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },

//   logout: async function (token, cancel = false) {
//     await api.request({
//       url: `/oauth/logout`,
//       method: `POST`,
//       data: {
//         refreshToken: token,
//       },
//       signal: cancel ? cancelApiObject[this.logout.name].handleRequestCancellation().signal : undefined,
//     });
//   },

};

// defining the cancel API object for OAuthService
const cancelApiObject = defineCancelApiObject(OAuthService);
