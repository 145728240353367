import React from "react";
import { Modal, Flex, Space } from "antd";
import IconConfirmSubmit from "../../assets/icon/icon_save_blue.js";
import CustomButton from "../../components/Button/CustomButton";

const ConfirmDialog = ({ isModalOpen, onCancel, onConfirm }) => {
  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={onCancel}
        closable={false}
        footer={null}
        centered
        width={337}
        // height={217}
        style={{
          borderRadius: "20px",
          overflow: "hidden",
        }}
      >
        <Flex justify="center" align="center" vertical>
          <div style={{ marginTop: 10 }}>
            <IconConfirmSubmit />
          </div>
          <div
            style={{
              fontWeight: 600,
              fontSize: 18,
              marginTop: 10,
            }}
          >
            Confirm Save
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: 14,
              marginTop: 10,
              color: "#475467",
            }}
          >
            Do you want to Save data ?
          </div>
          <Space style={{ marginTop: 15 }}>
            <CustomButton
              text="Cancel"
              type="primary"
              ghost={true}
              onClick={onCancel}
              width={100}
              height={32}
              // borderRadius="4px"
            />
            <CustomButton
              text="Comfirm"
              type="primary"
              // borderRadius="4px"
              onClick={onConfirm}
              width={100}
              height={32}
            />
          </Space>
        </Flex>
      </Modal>
    </>
  );
};

export default ConfirmDialog;
