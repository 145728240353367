import AuthConfig from "./AuthConfig";
// import { OAuthService } from "../service/OAuthService";

export const handleLogin = () => {
  const { url, clientId, redirectUri } = AuthConfig;
  window.location.href = `${url}/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`;
};

export const handleLogout = async () => {
  const { url, clientId, redirectUri } = AuthConfig;
  sessionStorage.clear();
  window.location.href = `${url}/logout?client_id=${clientId}&redirect_uri=${redirectUri}`;

  // try {
  //   const refreshToken = sessionStorage.getItem("refreshToken");
  //   await OAuthService.logout(refreshToken);
  //   sessionStorage.clear();
  //   window.location.href = "/";
  // } catch (error) {
  //   console.error();
  // }
};
