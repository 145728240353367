import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Table,
  Row,
  Space,
  Col,
  Flex,
  Upload,
  Empty,
  message,
  Button,
} from "antd";

import {
  UploadOutlined,
  LoadingOutlined,
  DeleteTwoTone,
  DeleteOutlined,
  CheckCircleFilled,
  ExclamationCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

import noData from "../assets/NoData.png";

import { ContactListService } from "../services/ContactListService";

const moment = require("moment");

const { Dragger } = Upload;

const UploadFile = () => {
  const columns = [
    {
      title: "No.",
      dataIndex: "id",
    },
    {
      title: "Queue name",
      dataIndex: "name",
    },
    {
      title: "Total Record",
      dataIndex: "total",
    },
  ];

  const [selectedFile, setSelectedFile] = useState();
  const [responseData, setResponseData] = useState();
  const [isUpload, setIsUpload] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailed, setShowFailed] = useState(false);

  const [valueSuccess, setValueSuccess] = useState({
    filename: "Filename.xls",
    date: "29 Oct 2020 - 23:30",
  });

  const [paginationValue, setPaginationValue] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    customRequest({ file, onSuccess, onError }) {
      const validTypes = [
        // "application/vnd.ms-excel", // .xls
        // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
        "text/csv", // .csv
        "application/csv", // .csv
        "text/plain", // .txt
      ];

      // const isValidFile = validTypes.includes(file.type) || file.name.endsWith('.xls') || file.name.endsWith('.xlsx') || file.name.endsWith('.csv');
      const isValidFile = validTypes.includes(file.type);

      if (!isValidFile) {
        message.error(`It is not a valid CSV or Text file.`);
        onError(new Error("Invalid file type"));
        return;
      }

      onSuccess(null, file);
      setSelectedFile(file);
    },
    // onChange(info) {
    //   const { status } = info.file;
    //   if (status !== "uploading") {
    //     // console.log(info.file, info.fileList);
    //     // setSelectedFile(info.file.name);
    //   }
    //   if (status === "done") {
    //     // message.success(`${info.file.name} file uploaded successfully.`);
    //     // setSelectedFile(info.file.name);
    //   } else if (status === "error") {
    //     // setSelectedFile(info.file.name);
    //     // message.error(`${info.file.name} file upload failed.`);
    //   }
    // },
    // onDrop(e) {
    //   // console.log("Dropped files", e.dataTransfer.files);
    // },
  };

  const uploadFile = async (file) => {
    try {
      const response = await ContactListService.uploadFile(file);

      const currentDate = moment().format("DD MMM YYYY - HH:mm");

      setValueSuccess({
        filename: file.name,
        date: currentDate,
      });

      setTimeout(() => {
        setSelectedFile();
        setIsUpload(false);
        setShowSuccess(true);
        setResponseData(response);
        setPaginationValue({
          current: paginationValue.current,
          pageSize: paginationValue.pageSize,
          total: response.length
        });
      }, 2000);
    } catch (error) {
      setShowFailed(true);
    }
  };

  const handleUpload = () => {
    setIsUpload(true);
    uploadFile(selectedFile);
  };

  const handleDeleteFile = () => {
    setSelectedFile();
    setShowFailed(false);
    setIsUpload(false);
  };

  const handleCancel = () => {
    setResponseData();
    setShowSuccess(false);
  };

  const handlePageChange = (pagination) => {
    setPaginationValue({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: paginationValue.total
    });
  };

  const itemRender = (_, type, originalElement) => {
    const { current, total, pageSize } = paginationValue;
    const totalPages = Math.ceil(total / pageSize);

    if (type === "prev") {
      if (current === 1) {
        return (
          <a style={{ pointerEvents: "none" }}>
            <LeftOutlined
              style={{
                // fontSize: "",
                color: "#d9d9d9",
              }}
            />
          </a>
        );
      }
      return (
        <a>
          <LeftOutlined
            style={{
              // fontSize: "",
              color: "#FF7F07",
            }}
          />
        </a>
      );
    }
    if (type === "next") {
      if (current === totalPages) {
        return (
          <a style={{ pointerEvents: "none" }}>
            <RightOutlined
              style={{
                // fontSize: "",
                color: "#d9d9d9",
              }}
            />
          </a>
        );
      }
      return (
        <a>
          <RightOutlined
            style={{
              // fontSize: "",
              color: "#FF7F07",
            }}
          />
        </a>
      );
    }
    return originalElement;
  };

  return (
    <>
      <h2
        style={{
          margin: "0px 0px",
        }}
      >
        Upload
      </h2>

      <div
        style={{
          // padding: 24,
          margin: "20px 0px",
          padding: "20px 30px",
          backgroundColor: "#fff",
          borderRadius: "10px",
          // height: "80vh"
        }}
      >
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Upload file
        </p>

        <div
          style={{
            width: "450px",
            height: "100px",
          }}
        >
          {isUpload ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                justifyContent: "center",
                height: "100%",
                background: "#F8F8F9",
                border: "1px solid #D0D5DD",
                borderRadius: "10px",
              }}
            >
              {showFailed ? (
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col
                    style={{
                      margin: "0px 10px",
                    }}
                  >
                    <Row
                      style={{
                        margin: "-25px 10px",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {selectedFile?.name}
                      </p>
                    </Row>
                    <Row
                      style={{
                        margin: "-25px 10px",
                      }}
                    >
                      <Space>
                        <ExclamationCircleOutlined
                          style={{
                            color: "red",
                          }}
                        />
                        <p
                          style={{
                            color: "red",
                            // fontSize: "12px"
                          }}
                        >
                          Upload failed.
                        </p>
                      </Space>
                    </Row>
                  </Col>
                  <div>
                    <Row>
                      <Col>
                        <DeleteOutlined
                          style={{
                            marginRight: "20px",
                          }}
                          onClick={handleDeleteFile}
                        />
                      </Col>
                    </Row>
                  </div>
                </Row>
              ) : (
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "30px",
                  }}
                >
                  <Col
                    style={{
                      textAlign: "center",
                      marginRight: "10px",
                    }}
                  >
                    <LoadingOutlined
                      style={{
                        fontSize: "30px",
                        color: "#FF7F07",
                      }}
                    />
                  </Col>
                  <Col
                    style={{
                      margin: "0px 10px",
                    }}
                  >
                    <Row
                      style={{
                        margin: "-25px 10px",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {selectedFile?.name}
                      </p>
                    </Row>
                    <Row
                      style={{
                        margin: "-25px 10px",
                      }}
                    >
                      <p
                        style={{
                          color: "#98A2B3",
                        }}
                      >
                        Loading...
                      </p>
                    </Row>
                  </Col>
                  {/* <Col
                  style={{
                    marginLeft: "130px",
                  }}
                >
                  <CloseCircleFilled
                    style={{
                      fontSize: "18px",
                      color: "#667085",
                    }}
                    // onClick={{}}
                  />
                </Col> */}
                </Row>
              )}
            </div>
          ) : (
            <Dragger
              {...props}
              style={{
                width: "100%",
                height: "100px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Col
                    style={{
                      textAlign: "center",
                      marginRight: "10px",
                    }}
                  >
                    <UploadOutlined
                      style={{
                        fontSize: "30px",
                        // color: "#FF7F07"
                      }}
                    />
                  </Col>
                  <Col
                    style={{
                      margin: "0px 10px",
                    }}
                  >
                    <Row
                      style={{
                        margin: "0px 0px",
                      }}
                    >
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </Row>
                    <Row
                      style={{
                        margin: "-15px 0px",
                      }}
                    >
                      <p className="ant-upload-hint">
                        or <a>Browse</a> to choose a file
                      </p>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Dragger>
          )}
        </div>

        <div
          style={{
            height: "50px",
            margin: "10px 0px",
          }}
        >
          <Flex
            justify="flex-start"
            align="center"
            style={{
              height: "100%",
            }}
          >
            <Button
              disabled={selectedFile ? false : true}
              type="primary"
              style={{
                marginRight: "20px",
              }}
              onClick={handleUpload}
            >
              Upload
            </Button>

            {selectedFile ? (
              <>
                <p
                  style={{
                    fontSize: "16px",
                    marginRight: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {selectedFile?.name}
                </p>
                <DeleteTwoTone twoToneColor="red" onClick={handleDeleteFile} />
              </>
            ) : (
              <p
                style={{
                  // fontSize: "",
                  marginRight: "10px",
                  color: "#868686",
                }}
              >
                Choose the file to upload.
              </p>
            )}
          </Flex>
        </div>

        <p
          style={{
            color: "red",
            marginTop: "-10px",
          }}
        >
          *You can upload one file at a time.
        </p>

        {showSuccess ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              justifyContent: "center",
              width: "450px",
              height: "68px",
              border: "1px solid #E3E3E3",
              borderRadius: "4px",
              marginTop: "30px",
            }}
          >
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // marginLeft: "0px",
              }}
            >
              <Col
                style={{
                  margin: "0px 10px",
                }}
              >
                <Row
                  style={{
                    margin: "-25px 10px",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {valueSuccess?.filename}
                  </p>
                </Row>
                <Row
                  style={{
                    margin: "-25px 10px",
                  }}
                >
                  <p
                    style={{
                      color: "#868686",
                    }}
                  >
                    {valueSuccess?.date}
                  </p>
                </Row>
              </Col>
              <div>
                <Row>
                  <Col>
                    <Space>
                      <p
                        style={{
                          color: "#1AC487",
                        }}
                      >
                        Successfully Uploaded
                      </p>
                      <CheckCircleFilled
                        style={{
                          color: "#1AC487",
                          marginRight: "10px",
                        }}
                      />
                      <DeleteOutlined
                        style={{
                          marginRight: "20px",
                        }}
                        onClick={handleCancel}
                      />
                    </Space>
                  </Col>
                </Row>
              </div>
            </Row>
          </div>
        ) : null}

        <p
          style={{
            fontWeight: "bold",
            marginTop: "30px",
          }}
        >
          Result
        </p>

        <div
          style={{
            minHeight: "70vh",
          }}
        >
          {responseData ? (
            <Table
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={responseData}
              pagination={{
                pageSizeOptions: [10, 25, 50, 100],
                itemRender: itemRender
              }}
              onChange={handlePageChange}
            />
          ) : (
            <div
              style={{
                width: "100%",
                height: "500px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> */}
              <img
                src={noData}
                alt=""
                style={{
                  width: "180px",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UploadFile;
