import Icon from "@ant-design/icons";

const icon = () => (
  <svg
    width="78"
    height="79"
    viewBox="0 0 78 79"
    fill="none"
  >
    <rect y="0.0898438" width="78" height="78" rx="20" fill="#FFE2E2" />
    <g clip-path="url(#clip0_7084_14650)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M50.2503 20.2853V20.8523C52.6371 21.0708 55.0042 21.3581 57.3494 21.7123C58.2178 21.8434 59.0831 21.9837 59.9453 22.1331C60.9657 22.3098 61.6495 23.2803 61.4728 24.3006C61.296 25.3209 60.3256 26.0048 59.3053 25.8281C59.1313 25.7979 58.9571 25.7682 58.7829 25.7388L56.2693 58.4151C55.9687 62.3225 52.7104 65.3398 48.7914 65.3398H29.2092C25.2902 65.3398 22.0319 62.3225 21.7313 58.4151L19.2178 25.7388C19.0435 25.7682 18.8693 25.7979 18.6953 25.8281C17.675 26.0048 16.7046 25.3209 16.5278 24.3006C16.3511 23.2803 17.0349 22.3098 18.0553 22.1331C18.9175 21.9837 19.7829 21.8434 20.6512 21.7123C22.9964 21.3581 25.3635 21.0708 27.7503 20.8523V20.2853C27.7503 16.3741 30.7816 13.0353 34.7889 12.9071C36.1873 12.8624 37.5912 12.8398 39.0003 12.8398C40.4094 12.8398 41.8133 12.8624 43.2117 12.9071C47.219 13.0353 50.2503 16.3741 50.2503 20.2853ZM34.9088 16.6552C36.2672 16.6117 37.6312 16.5898 39.0003 16.5898C40.3694 16.5898 41.7334 16.6117 43.0918 16.6552C44.9784 16.7155 46.5003 18.2997 46.5003 20.2853V20.5669C44.0194 20.4163 41.5187 20.3398 39.0003 20.3398C36.482 20.3398 33.9812 20.4163 31.5003 20.5669V20.2853C31.5003 18.2997 33.0223 16.7155 34.9088 16.6552ZM34.0218 31.5178C33.982 30.483 33.1109 29.6764 32.0761 29.7162C31.0414 29.756 30.2348 30.6271 30.2746 31.6619L31.14 54.1619C31.1798 55.1967 32.0509 56.0033 33.0856 55.9635C34.1204 55.9237 34.927 55.0526 34.8872 54.0178L34.0218 31.5178ZM47.7237 31.6619C47.7635 30.6271 46.9569 29.756 45.9222 29.7162C44.8874 29.6764 44.0163 30.483 43.9765 31.5178L43.1111 54.0178C43.0713 55.0526 43.8779 55.9237 44.9127 55.9635C45.9474 56.0033 46.8185 55.1967 46.8583 54.1619L47.7237 31.6619Z"
        fill="#FF4D4F"
      />
    </g>
    <defs>
      <clipPath id="clip0_7084_14650">
        <rect
          width="60"
          height="60"
          fill="white"
          transform="translate(9 9.08984)"
        />
      </clipPath>
    </defs>
  </svg>
);

const Icon_Delete_Dialog = (props) => <Icon component={icon} {...props} />;

export default Icon_Delete_Dialog;
