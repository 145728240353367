import React, { useState } from "react";
import { Modal, Flex, Space, Tag, Spin } from "antd";
import IconConfirmSubmit from "../../../../assets/icon/icon_save_blue";
import {
  CustomButton,
  SuccessDialog,
  FailedDialog,
} from "../../../../components";
import { CampaingService } from "../../../../services/CampaignService";

const ModalRecycle = ({ onClose, open, items, fetchData }) => {
  const [loading, setLoading] = useState(false);
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [isOpenFailed, setIsOpenFailed] = useState(false);

  const handleUpdateRecycle = async () => {
    try {
      setLoading(true);
      let ids = items.map((item) => item.id);
      let data = {
        ids: ids,
      };
      await CampaingService.Recycle(data);
      setTimeout(() => {
        setLoading(false);
        onClose();
        setIsOpenSuccess(true);
        setTimeout(() => {
          setIsOpenSuccess(false);
          fetchData();
        }, 1000);
      }, 1000);
    } catch (error) {
      console.error();
      setTimeout(() => {
        setLoading(false);
        setIsOpenFailed(true);
      }, 1000);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={onClose}
        closable={false}
        footer={null}
        centered
        width={610}
        // height={217}
        style={{
          borderRadius: "20px",
          overflow: "hidden",
        }}
      >
        <Flex justify="center" align="center" vertical>
          <div style={{ marginTop: 10 }}>
            <IconConfirmSubmit />
          </div>
          <div
            style={{
              fontWeight: 600,
              fontSize: 18,
              marginTop: 10,
            }}
          >
            Confirm Recycle Data
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: 14,
              marginTop: 10,
              color: "#475467",
            }}
          >
            Do you want to recycle data ?
          </div>
          <Spin spinning={loading} fullscreen />
          <div
            style={{
              width: "556px",
              height: "153px",
              borderRadius: "8px",
              border: "1px solid #D0D5DD",
              margin: "20px 0px",
              padding: "5px",
              overflowY: "scroll",
            }}
          >
            <Space wrap>
              {items.map((item) => (
                <Tag color="default">{item.name}</Tag>
              ))}
            </Space>
          </div>
          <Space style={{ marginTop: 15 }}>
            <CustomButton
              text="Cancel"
              type="primary"
              ghost={true}
              onClick={onClose}
              width={100}
              height={32}
              // borderRadius="4px"
            />
            <CustomButton
              text="Comfirm"
              type="primary"
              // borderRadius="4px"
              onClick={() => {
                handleUpdateRecycle();
              }}
              width={100}
              height={32}
            />
          </Space>
        </Flex>
      </Modal>

      <SuccessDialog isModalOpen={isOpenSuccess} />

      <FailedDialog
        isModalOpen={isOpenFailed}
        onCancel={() => {
          setIsOpenFailed(false);
        }}
        onConfirm={() => {
          setIsOpenFailed(false);
          handleUpdateRecycle();
        }}
      />
    </>
  );
};

export default ModalRecycle;
