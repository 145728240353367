import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Table,
  Space,
  Button,
  Form,
  Input,
  Flex,
  Modal,
  Row,
  Col,
  DatePicker,
  Pagination,
  Select,
} from "antd";

import {
  SearchOutlined,
  SyncOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { CampaingService } from "../services/CampaignService";

import Success from "../assets/success.png";
import Failed from "../assets/failed.png";
import { render } from "@testing-library/react";

const { RangePicker } = DatePicker;

const { Option } = Select;

const Campaign = () => {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Caller address",
      dataIndex: "callerAddress",
    },
    {
      title: "Agent Script",
      // dataIndex: "",
      render: (text, record) => <p>{record.script.name}</p>,
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      // sorter: true,
    },
    {
      title: "Date Modified",
      dataIndex: "dateModified",
      // sorter: true,
    },
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   render: (text, record) => (
    //     <span style={{ whiteSpace: "nowrap" }}>
    //       <Tooltip placement="bottom" title="View / Edit"></Tooltip>
    //     </span>
    //   ),
    // },
  ];

  const [formSearch] = Form.useForm();
  const [formCaller] = Form.useForm();
  const [formSchedule] = Form.useForm();
  // const [formAgentScript] = Form.useForm();

  const [data, setData] = useState([]);
  // const [ref, setRef] = useState();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // const [keywords, setKeywords] = useState("");
  const [disableBtn, setDisableBtn] = useState(true);
  const [isModalCallerOpen, setIsModalCallerOpen] = useState(false);
  const [isModalScheduleOpen, setIsModalScheduleOpen] = useState(false);
  const [isModalAgentScriptOpen, setIsModalAgentScriptOpen] = useState(false);

  const [isModal, setIsModal] = useState(false);
  const [isBtnCallerRoSchedule, setIsBtnCallerRoSchedule] = useState("");
  const [isAction, setIsAction] = useState("");

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);

  useEffect(() => {
    const check = selectedRowKeys.length !== 0 ? false : true;
    setDisableBtn(check);
  }, [selectedRowKeys]);

  const getParams = (params) => ({
    // Name: keywords,
    Name: formSearch.getFieldsValue().search,
    PageSize: params.pagination?.pageSize,
    PageNumber: params.pagination?.current,
  });

  const fetchData = async () => {
    setLoading(true);

    let params = getParams(tableParams);
    let response = await CampaingService.search(params);
    setData(response.entities);
    setLoading(false);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: response.total,
      },
    });

    console.log(data);
  };

  // const fetchRefApi = async () => {
  //   let response = await RefApi.getAll();
  //   // console.log(response);
  //   setRef(response);
  // };

  const onDefaultPagination = () => {
    tableParams.pagination = {
      current: 1,
      pageSize: 10,
    };
  };

  const onSearchScb = async () => {
    setSelectedRowKeys([]);

    onDefaultPagination();

    fetchData();
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // const handleSearchChange = (event) => {
  //   setKeywords(event.target.value);
  // };

  const clearSearch = () => {
    formSearch.resetFields();
    setSelectedRowKeys([]);

    onDefaultPagination();

    fetchData();
  };

  const showModalCaller = () => {
    setIsModalCallerOpen(true);
  };

  const showModalSchedule = () => {
    setIsModalScheduleOpen(true);
  };

  // const showModalAgentScript = () => {
  //   setIsModalAgentScriptOpen(true);
  // };

  const handleCallerCancel = () => {
    setIsModalCallerOpen(false);
    setIsBtnCallerRoSchedule("");
  };

  const handleScheduleCancel = () => {
    setIsModalScheduleOpen(false);
    setIsBtnCallerRoSchedule("");
  };

  // const handleAgentScriptCancel = () => {
  //   setIsModalAgentScriptOpen(false);
  //   // setIsBtnCallerRoSchedule("");
  // };

  const handleIsModalCancel = () => {
    setIsModal(false);
  };

  const onFinishCaller = async () => {
    // onCallerAddress();
    const { getFieldsValue } = formCaller;

    const dataValue = {
      ids: selectedRowKeys,
      callerAddress: getFieldsValue().callerAddress,
    };

    try {
      const response = await CampaingService.updateCaller(dataValue);

      setIsAction("success");
      setIsModalCallerOpen(false);
      setIsModal(true);
      setIsBtnCallerRoSchedule("");
      setTimeout(() => {
        setIsModal(false);
        fetchData();
      }, 2000);
    } catch (error) {
      setIsAction("failed");
      setIsModalCallerOpen(false);
      setIsModal(true);
    }
  };

  const onFinishSchedule = async () => {
    const { getFieldsValue } = formSchedule;

    const dataValue = {
      ids: selectedRowKeys,
      startDate: getFieldsValue().schedule[0].format(),
      endDate: getFieldsValue().schedule[1].format(),
    };

    try {
      const response = await CampaingService.updateSchedule(dataValue);

      setIsAction("success");
      setIsModalScheduleOpen(false);
      setIsModal(true);
      setIsBtnCallerRoSchedule("");
      setTimeout(() => {
        setIsModal(false);
        fetchData();
      }, 2000);
    } catch (error) {
      setIsAction("failed");
      setIsModalScheduleOpen(false);
      setIsModal(true);
    }
  };

  // const onFinishAgentScript = async () => {
  //   const { getFieldsValue } = formAgentScript;

  //   const dataValue = {
  //     // id: ,
  //     name: getFieldsValue().agentScript,
  //     // selfUri:
  //   };

  //   // try {

  //   // } catch (error) {

  //   // }
  // };

  const handlePageChange = (page, pageSize) => {
    setTableParams({
      pagination: {
        current: page,
        pageSize,
        total: tableParams.pagination?.total,
      },
    });

    if (pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handlePageSizeChange = (current, pageSize) => {
    setTableParams({
      pagination: {
        current: 1,
        pageSize,
        // total: tableParams.pagination?.total
      },
    });
  };

  const itemRender = (_, type, originalElement) => {
    const { current, total, pageSize } = tableParams.pagination;
    const totalPages = Math.ceil(total / pageSize);

    if (type === "prev") {
      if (current === 1) {
        return (
          <a style={{ pointerEvents: "none" }}>
            <LeftOutlined
              style={{
                // fontSize: "",
                color: "#d9d9d9",
              }}
            />
          </a>
        );
      }
      return (
        <a>
          <LeftOutlined
            style={{
              // fontSize: "",
              color: "#FF7F07",
            }}
          />
        </a>
      );
    }
    if (type === "next") {
      if (current === totalPages) {
        return (
          <a style={{ pointerEvents: "none" }}>
            <RightOutlined
              style={{
                // fontSize: "",
                color: "#d9d9d9",
              }}
            />
          </a>
        );
      }
      return (
        <a>
          <RightOutlined
            style={{
              // fontSize: "",
              color: "#FF7F07",
            }}
          />
        </a>
      );
    }
    return originalElement;
  };

  return (
    <>
      <h2
        style={{
          margin: "0px 0px",
        }}
      >
        Campaign
      </h2>

      <div
        style={{
          // padding: 24,
          margin: "20px 0px",
          padding: "20px 30px",
          backgroundColor: "#fff",
          borderRadius: "10px",
        }}
      >
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Search
        </p>
        <Row gutter={10}>
          <Col flex="auto">
            <Form hideRequiredMark form={formSearch}>
              <Form.Item name="search">
                <Input
                  placeholder="Search Name ..."
                  suffix={<SearchOutlined />}
                  // onChange={handleSearchChange}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col flex="100px">
            <Button
              icon={<SyncOutlined />}
              style={{
                width: "100%",
              }}
              onClick={clearSearch}
            >
              Clear
            </Button>
          </Col>
          <Col flex="100px">
            <Button
              icon={<SearchOutlined />}
              type="primary"
              style={{
                width: "100%",
              }}
              onClick={onSearchScb}
            >
              Search
            </Button>
          </Col>
        </Row>
      </div>

      <div
        style={{
          margin: "20px 0px",
          padding: "20px 30px",
          backgroundColor: "#fff",
          borderRadius: "10px",
        }}
      >
        <Row gutter={10}>
          <Col flex="auto">
            <p
              style={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              list
            </p>
          </Col>
          {/* <Col flex="140px">
            <Button
              disabled={disableBtn}
              type="primary"
              ghost
              style={{
                width: "100%",
              }}
              onClick={() => {
                formAgentScript.resetFields();
                setIsModalAgentScriptOpen("AgentScript");
                showModalAgentScript();
              }}
            >
              Edit Agent Script
            </Button>
          </Col> */}
          <Col flex="140px">
            <Button
              disabled={disableBtn}
              type="primary"
              ghost
              style={{
                width: "100%",
              }}
              onClick={() => {
                formCaller.resetFields();
                setIsBtnCallerRoSchedule("caller");
                showModalCaller();
              }}
            >
              Caller
            </Button>
          </Col>
          <Col flex="140px">
            <Button
              disabled={disableBtn}
              type="primary"
              ghost
              style={{
                width: "100%",
              }}
              onClick={() => {
                formSchedule.resetFields();
                setIsBtnCallerRoSchedule("schedule");
                showModalSchedule();
              }}
            >
              Schedule
            </Button>
          </Col>
        </Row>

        <Table
          style={{
            margin: "8px 0",
          }}
          rowSelection={{
            type: "checkbox",
            preserveSelectedRowKeys: true,
            ...rowSelection,
          }}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={data}
          // pagination={{
          //   ...tableParams.pagination,
          //   position: ["bottomCenter"],
          //   showTotal: (total, range) => `Total : ${total} items`,
          //   pageSizeOptions: [10, 25, 50, 100],
          // }}
          pagination={false}
          onChange={handlePageChange}
          loading={loading}
          scroll={{
            x: 1000,
          }}
        />

        {data.length !== 0 ? (
          <Row justify="space-between" align="middle" style={{ marginTop: 16 }}>
            <Col>
              <span
                style={{
                  color: "#667085",
                }}
              >
                Total : {tableParams.pagination?.total} items
              </span>
            </Col>
            <Col>
              <Pagination
                style={{ pointerEvents: loading ? "none" : "auto" }}
                current={tableParams.pagination?.current}
                pageSize={tableParams.pagination?.pageSize}
                total={tableParams.pagination?.total}
                onChange={handlePageChange}
                showSizeChanger={false}
                itemRender={itemRender}
              />
            </Col>
            <Col>
              <Select
                value={tableParams.pagination?.pageSize}
                onChange={(pageSize) =>
                  handlePageSizeChange(
                    tableParams.pagination?.current,
                    pageSize
                  )
                }
                style={{
                  width: "110px",
                }}
              >
                <Option value={10}>10 / page</Option>
                <Option value={25}>25 / page</Option>
                <Option value={50}>50 / page</Option>
                <Option value={100}>100 / page</Option>
              </Select>
            </Col>
          </Row>
        ) : null}

        <Modal
          title={
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              Change Caller
            </p>
          }
          open={isModalCallerOpen}
          footer={null}
          onCancel={handleCallerCancel}
          // loading={true}
        >
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Caller
          </p>
          <Form hideRequiredMark form={formCaller} onFinish={onFinishCaller}>
            <Form.Item
              name="callerAddress"
              rules={[
                {
                  required: true,
                  message: "Please fill in information.",
                },
                {
                  pattern: /^\+\d{10}$/,
                  message: "Please enter a valid phone number.",
                },
              ]}
            >
              <Input placeholder="Caller" allowClear />
            </Form.Item>
          </Form>

          <Flex
            justify="flex-end"
            style={{
              margin: "20px 0px",
            }}
          >
            <Space>
              <Button
                style={{
                  width: "100px",
                }}
                onClick={() => {
                  formCaller.resetFields();
                  setIsBtnCallerRoSchedule("");
                  handleCallerCancel();
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  width: "100px",
                }}
                type="primary"
                onClick={() => {
                  formCaller.submit();
                }}
              >
                Save
              </Button>
            </Space>
          </Flex>
        </Modal>

        <Modal
          title={
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              Change Schedule
            </p>
          }
          open={isModalScheduleOpen}
          footer={null}
          onCancel={handleScheduleCancel}
        >
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Schedule
          </p>
          <Form
            hideRequiredMark
            form={formSchedule}
            onFinish={onFinishSchedule}
          >
            <Form.Item
              name="schedule"
              rules={[
                {
                  required: true,
                  message: "Please fill in information.",
                },
              ]}
            >
              <RangePicker
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Form>

          <Flex
            justify="flex-end"
            style={{
              margin: "20px 0px",
            }}
          >
            <Space>
              <Button
                style={{
                  width: "100px",
                }}
                onClick={() => {
                  formSchedule.resetFields();
                  setIsBtnCallerRoSchedule("");
                  handleScheduleCancel();
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  width: "100px",
                }}
                type="primary"
                onClick={() => {
                  formSchedule.submit();
                }}
              >
                Save
              </Button>
            </Space>
          </Flex>
        </Modal>

        {/* <Modal
          title={
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              Edit Agent Script
            </p>
          }
          open={isModalAgentScriptOpen}
          footer={null}
          onCancel={handleAgentScriptCancel}
        >
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Agent Script
          </p>
          <Form
            hideRequiredMark
            form={formAgentScript}
            onFinish={onFinishAgentScript}
          >
            <Form.Item
              name="agentScript"
              rules={[
                {
                  required: true,
                  message: "Please fill in information.",
                },
              ]}
            >
              <Select
                // disabled={true}
                placeholder="Selete"
                // options={ref?.map((i) => ({
                //   value: i.id,
                //   label: i.id + " | " + i.name,
                // }))}
                allowClear
              />
            </Form.Item>
          </Form>

          <Flex
            justify="flex-end"
            style={{
              margin: "20px 0px",
            }}
          >
            <Space>
              <Button
                style={{
                  width: "100px",
                }}
                onClick={() => {
                  formAgentScript.resetFields();
                  // setIsBtnCallerRoSchedule("");
                  handleAgentScriptCancel();
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  width: "100px",
                }}
                type="primary"
                onClick={() => {
                  formAgentScript.submit();
                }}
              >
                Save
              </Button>
            </Space>
          </Flex>
        </Modal> */}

        <Modal
          open={isModal}
          footer={null}
          onCancel={handleIsModalCancel}
          width={400}
          style={{ borderRadius: "100px" }}
        >
          {isAction === "success" ? (
            <img
              src={Success}
              alt=""
              style={{
                width: "350px",
                // height: "auto",
                display: "block",
                margin: "auto",
              }}
            />
          ) : (
            <div>
              <img
                src={Failed}
                alt=""
                style={{
                  width: "350px",
                  // height: "auto",
                  display: "block",
                  margin: "auto",
                }}
              />
              <Flex
                justify="center"
                style={{
                  margin: "20px 0px",
                }}
              >
                <Button
                  style={{
                    width: "100px",
                    height: "40px",
                  }}
                  type="primary"
                  onClick={() => {
                    handleIsModalCancel();
                    if (isBtnCallerRoSchedule === "caller") {
                      showModalCaller();
                    } else {
                      showModalSchedule();
                    }
                  }}
                >
                  Try Again
                </Button>
              </Flex>
            </div>
          )}
        </Modal>
      </div>
    </>
  );
};

export default Campaign;
