import "./Layout.css";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Layout, theme, Flex } from "antd";
import { SiderMenu, CustomHeader } from "../../components";

const { Sider, Content } = Layout;

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <>
      <Layout className="layout-container">
        <Sider
          className="sider-background"
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            // console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            // console.log(collapsed, type);
            setCollapsed(collapsed);
          }}
          //   width={280}
        >
          <div className="sider-container">
            <div
              // className="demo-logo-vertical"
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Flex justify="center">
                <h1 style={{ color: "#888", opacity: 0.5 }}>LOGO</h1>
                {/* <img src={logo} width={100}/> */}
              </Flex>
            </div>

            <SiderMenu />
          </div>
        </Sider>
        <Layout>
          <CustomHeader />
          <Content className="content-container">
            {/* <div> */}
            <Outlet />
            {/* </div> */}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
export default App;
