import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const UserService = {
  userMe: async function (cancel = false) {
    const response = await api.request({
      url: "/users/me",
      method: "GET",
      signal: cancel ? cancelApiObject[this.userMe.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },
};

// defining the cancel API object for UserService
const cancelApiObject = defineCancelApiObject(UserService);
