import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const FileService = {

  convertFile: async function (file, cancel = false) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await api.request({
      url: `/files/convert/gc`,
      method: `POST`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
      responseType: 'blob',
      signal: cancel ? cancelApiObject[this.convertFile.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },
};

// defining the cancel API object for ContactListService
const cancelApiObject = defineCancelApiObject(FileService);
