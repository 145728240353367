import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const ContactListService = {
  search: async function (params, cancel = false) {
    const response = await api.request({
      url: "/contactlists",
      method: "GET",
      params: params,
      signal: cancel ? cancelApiObject[this.search.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },

  clearAll: async function (data, cancel = false) {
    await api.request({
      url: `/contactlists/clearall`,
      method: `POST`,
      data: data,
      signal: cancel ? cancelApiObject[this.clearAll.name].handleRequestCancellation().signal : undefined,
    });
  },

  clear: async function (data, cancel = false) {
    await api.request({
      url: `/contactlists/clear`,
      method: `POST`,
      data: data,
      signal: cancel ? cancelApiObject[this.clear.name].handleRequestCancellation().signal : undefined,
    });
  },

  uploadFile: async function (file, cancel = false) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await api.request({
      url: `/contactlists/upload`,
      method: `POST`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
      signal: cancel ? cancelApiObject[this.uploadFile.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },

  testUploadFile: async function (data, cancel = false) {
    const response = await api.request({
      url: `/contactlists/test-upload`,
      method: `POST`,
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
      signal: cancel ? cancelApiObject[this.testUploadFile.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },
};

// defining the cancel API object for ContactListService
const cancelApiObject = defineCancelApiObject(ContactListService);
