import React from "react";
import { Select } from "antd";
import "./CustomSelect.css";

const { Option } = Select;

const CustomSelect = ({
  options,
  onChange,
  value,
  placeholder = "",
  width,
  height,
}) => {
  return (
    <>
      <Select
        className="select-custom"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        allowClear
        style={{ width, height }}
      >
        {options?.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default CustomSelect;
