import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Table,
  Space,
  Button,
  Form,
  Input,
  Flex,
  Modal,
  Row,
  Col,
  DatePicker,
  ConfigProvider,
  Pagination,
  Select,
} from "antd";

import {
  SearchOutlined,
  SyncOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

import { ContactListService } from "../services/ContactListService";

import Success from "../assets/success.png";
import Failed from "../assets/failed.png";

const { Option } = Select;

const ContactList = () => {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      // sorter: true,
    },
    // {
    //   title: "Date Modified",
    //   dataIndex: "dateModified",
    //   // sorter: true,
    // },
  ];

  const [formSearch] = Form.useForm();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // const [keywords, setKeywords] = useState("");
  const [disableBtn, setDisableBtn] = useState(true);
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isBtnClearRoClearAll, setIsBtnClearRoClearAll] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [isAction, setIsAction] = useState("");

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);

  useEffect(() => {
    const check = selectedRowKeys.length !== 0 ? false : true;
    setDisableBtn(check);
  }, [selectedRowKeys]);

  const getParams = (params) => ({
    // Name: keywords,
    Name: formSearch.getFieldsValue().search,
    PageSize: params.pagination?.pageSize,
    PageNumber: params.pagination?.current,
  });

  const fetchData = async () => {
    setLoading(true);

    let params = getParams(tableParams);
    let response = await ContactListService.search(params);
    setData(response.entities);
    setLoading(false);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: response.total,
      },
    });
  };

  const onDefaultPagination = () => {
    tableParams.pagination = {
      current: 1,
      pageSize: 10,
    };
  };

  const onSearchScb = async () => {
    setSelectedRowKeys([]);

    onDefaultPagination();

    fetchData();
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // const handleSearchChange = (event) => {
  //   setKeywords(event.target.value);
  // };

  const clearSearch = async () => {
    formSearch.resetFields();
    setSelectedRowKeys([]);

    onDefaultPagination();

    fetchData();
  };

  const handleClear = async () => {
    let dataValue = {
      ids: selectedRowKeys,
    };

    try {
      const response = await ContactListService.clear(dataValue);

      setIsAction("success");
      setIsModalConfirm(false);
      setIsBtnClearRoClearAll("");
      setIsModal(true);
      setTimeout(() => {
        setIsModal(false);

        fetchData();
      }, 2000);
    } catch (error) {
      setIsAction("failed");
      setIsModalConfirm(false);
      setIsModal(true);
    }
  };

  const handleClearAll = async () => {
    try {
      const response = await ContactListService.clearAll();

      setIsAction("success");
      setIsModalConfirm(false);
      setIsBtnClearRoClearAll("");
      setIsModal(true);
      setTimeout(() => {
        setIsModal(false);

        fetchData();
      }, 2000);
    } catch (error) {
      setIsAction("failed");
      setIsModalConfirm(false);
      setIsModal(true);
    }
  };

  const showModelConfirm = () => {
    setIsModalConfirm(true);
  };

  const handleModalConfirmCancel = () => {
    setIsModalConfirm(false);
    setIsBtnClearRoClearAll("");
  };

  const handleIsModalCancel = () => {
    setIsModal(false);
  };

  const handlePageChange = (page, pageSize) => {
    setTableParams({
      pagination: {
        current: page,
        pageSize,
        total: tableParams.pagination?.total
      },
    });

    if (pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handlePageSizeChange = (current, pageSize) => {
    setTableParams({
      pagination: {
        current: 1,
        pageSize,
        // total: tableParams.pagination?.total
      },
    });
  };

  const itemRender = (_, type, originalElement) => {
    const { current, total, pageSize } = tableParams.pagination;
    const totalPages = Math.ceil(total / pageSize);

    console.log(totalPages);

    if (type === "prev") {
      if (current === 1) {
        return (
          <a style={{ pointerEvents: "none" }}>
            <LeftOutlined
              style={{
                // fontSize: "",
                color: "#d9d9d9",
              }}
            />
          </a>
        );
      }
      return (
        <a>
          <LeftOutlined
            style={{
              // fontSize: "",
              color: "#FF7F07",
            }}
          />
        </a>
      );
    }
    if (type === "next") {
      if (current === totalPages) {
        return (
          <a style={{ pointerEvents: "none" }}>
            <RightOutlined
              style={{
                // fontSize: "",
                color: "#d9d9d9",
              }}
            />
          </a>
        );
      }
      return (
        <a>
          <RightOutlined
            style={{
              // fontSize: "",
              color: "#FF7F07",
            }}
          />
        </a>
      );
    }
    return originalElement;
  };

  return (
    <>
      <h2
        style={{
          margin: "0px 0px",
        }}
      >
        Contact List
      </h2>

      <div
        style={{
          // padding: 24,
          margin: "20px 0px",
          padding: "20px 30px",
          backgroundColor: "#fff",
          borderRadius: "10px",
        }}
      >
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Search
        </p>
        <Row gutter={10}>
          <Col flex="auto">
            <Form hideRequiredMark form={formSearch}>
              <Form.Item name="search">
                <Input
                  placeholder="Search Name ..."
                  suffix={<SearchOutlined />}
                  // onChange={handleSearchChange}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col flex="100px">
            <Button
              icon={<SyncOutlined />}
              style={{
                width: "100%",
              }}
              onClick={clearSearch}
            >
              Clear
            </Button>
          </Col>
          <Col flex="100px">
            <Button
              icon={<SearchOutlined />}
              type="primary"
              style={{
                width: "100%",
              }}
              onClick={onSearchScb}
            >
              Search
            </Button>
          </Col>
        </Row>
      </div>

      <div
        style={{
          margin: "20px 0px",
          padding: "20px 30px",
          backgroundColor: "#fff",
          borderRadius: "10px",
        }}
      >
        <Row gutter={10}>
          <Col flex="auto">
            <p
              style={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              list
            </p>
          </Col>
          <Col flex="100px">
            <Button
              disabled={disableBtn}
              type="primary"
              ghost
              style={{
                width: "100%",
              }}
              onClick={() => {
                setIsBtnClearRoClearAll("clear");
                showModelConfirm();
                // handleClear();
              }}
            >
              Clear
            </Button>
          </Col>
          <Col flex="100px">
            <Button
              // disabled={disableBtn}
              type="primary"
              ghost
              style={{
                width: "100%",
              }}
              onClick={() => {
                setIsBtnClearRoClearAll("clearAll");
                showModelConfirm();
                // handleClearAll();
              }}
            >
              Clear All
            </Button>
          </Col>
        </Row>

        <Table
          style={{
            margin: "8px 0",
          }}
          rowSelection={{
            type: "checkbox",
            preserveSelectedRowKeys: true,
            ...rowSelection,
          }}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={data}
          // pagination={{
          //   ...tableParams.pagination,
          //   position: ["bottomCenter"],
          //   showTotal: (total, range) => `Total : ${total} items`,
          //   pageSizeOptions: [10, 25, 50, 100]
          // }}
          pagination={false}
          onChange={handlePageChange}
          loading={loading}
          scroll={{
            x: 1000,
          }}
        />

        {data.length !== 0 ? (
          <Row justify="space-between" align="middle" style={{ marginTop: 16 }}>
            <Col>
              <span
                style={{
                  color: "#667085",
                }}
              >
                Total : {tableParams.pagination?.total} items
              </span>
            </Col>
            <Col>
              <Pagination
                style={{ pointerEvents: loading ? "none" : "auto" }}
                current={tableParams.pagination?.current}
                pageSize={tableParams.pagination?.pageSize}
                total={tableParams.pagination?.total}
                onChange={handlePageChange}
                showSizeChanger={false}
                itemRender={itemRender}
              />
            </Col>
            <Col>
              <Select
                value={tableParams.pagination?.pageSize}
                onChange={(pageSize) =>
                  handlePageSizeChange(
                    tableParams.pagination?.current,
                    pageSize
                  )
                }
                style={{
                  width: "110px",
                }}
              >
                <Option value={10}>10 / page</Option>
                <Option value={25}>25 / page</Option>
                <Option value={50}>50 / page</Option>
                <Option value={100}>100 / page</Option>
              </Select>
            </Col>
          </Row>
        ) : null}

        <Modal
          // title={}
          open={isModalConfirm}
          footer={false}
          onCancel={handleModalConfirmCancel}
          width={400}
        >
          <Flex justify="center" style={{ margin: "20px 0px" }}>
            <ExclamationCircleOutlined
              style={{ fontSize: "60px", color: "#FF7F07" }}
            />
          </Flex>

          <Flex justify="center" style={{ margin: "20px 0px" }}>
            <p
              style={{
                fontSize: "18px",
              }}
            >
              Are you sure?
            </p>
          </Flex>

          <Flex
            justify="center"
            style={
              {
                // margin: "20px 0px",
              }
            }
          >
            <Space>
              <Button
                style={{
                  width: "100px",
                  height: "40px",
                }}
                onClick={() => {
                  handleModalConfirmCancel();
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  width: "100px",
                  height: "40px",
                }}
                type="primary"
                onClick={() => {
                  if (isBtnClearRoClearAll === "clear") {
                    handleClear();
                  } else {
                    handleClearAll();
                  }
                }}
              >
                Confirm
              </Button>
            </Space>
          </Flex>
        </Modal>

        <Modal
          open={isModal}
          footer={null}
          onCancel={handleIsModalCancel}
          width={400}
          style={{ borderRadius: "100px" }}
        >
          {isAction === "success" ? (
            <img
              src={Success}
              alt=""
              style={{
                width: "350px",
                // height: "auto",
                display: "block",
                margin: "auto",
              }}
            />
          ) : (
            <img
              src={Failed}
              alt=""
              style={{
                width: "350px",
                // height: "auto",
                display: "block",
                margin: "auto",
              }}
            />
          )}
        </Modal>
      </div>
    </>
  );
};

export default ContactList;
