import React, { useState, useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Drawer, Space, Select, Tag, Flex, Spin, Form } from "antd";
import {
  CustomButton,
  ConfirmDialog,
  SuccessDialog,
  FailedDialog,
} from "../../../../components";
import { CampaingService } from "../../../../services/CampaignService";

const { Option } = Select;

const DrawerStatus = ({ onClose, open, items, fetchData }) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [isOpenFailed, setIsOpenFailed] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [open, onClose]);

  const handleUpdateStatus = async () => {
    try {
      setLoading(true);
      let ids = items.map((item) => item.id);
      let data = {
        ids: ids,
        campaignStatus: value,
      };
      await CampaingService.updateStatus(data);
      setTimeout(() => {
        setLoading(false);
        onClose();
        setIsOpenSuccess(true);
        setTimeout(() => {
          setIsOpenSuccess(false);
          fetchData();
        }, 1000);
      }, 1000);
    } catch (error) {
      console.error();
      setTimeout(() => {
        setLoading(false);
        setIsOpenFailed(true);
      }, 1000);
    }
  };

  const handleChange = (value) => {
    setValue(value);
  };

  const onFinish = () => {
    setIsOpenConfirm(true);
  };

  return (
    <>
      <Drawer
        title="Change Status"
        placement="right"
        width={470}
        closable={false}
        onClose={() => (onClose(), setValue())}
        open={open}
        extra={
          <Space>
            <CloseOutlined onClick={() => (onClose(), setValue())} />
          </Space>
        }
        footer={
          <Flex justify="flex-end">
            <Space>
              <CustomButton
                text="Cancel"
                onClick={() => (onClose(), setValue())}
                width={100}
              />
              <CustomButton
                text="Save"
                type="primary"
                onClick={() => form.submit()}
                width={100}
              />
            </Space>
          </Flex>
        }
      >
        <Spin spinning={loading} fullscreen />
        <p style={{ fontWeight: "bold" }}>Campaign to change</p>
        <div
          style={{
            width: "410px",
            height: "100px",
            borderRadius: "8px",
            border: "1px solid #D0D5DD",
            margin: "20px 0px",
            padding: "5px",
            overflowY: "scroll",
          }}
        >
          <Space wrap>
            {items.map((item) => (
              <Tag color="default">{item.name}</Tag>
            ))}
          </Space>
        </div>

        <p style={{ fontWeight: "bold" }}>Status</p>
        <Form
          hideRequiredMark
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name="status"
            rules={[{ required: true, message: "Please Select!" }]}
          >
            <Select
              // value={value}
              onChange={handleChange}
              placeholder="Select"
              allowClear
              style={{ width: "100%" }}
            >
              <Option value="on">On</Option>
              <Option value="off">Off</Option>
            </Select>
          </Form.Item>
        </Form>
      </Drawer>

      <ConfirmDialog
        isModalOpen={isOpenConfirm}
        onCancel={() => {
          setIsOpenConfirm(false);
        }}
        onConfirm={() => {
          handleUpdateStatus();
          setIsOpenConfirm(false);
        }}
      />

      <SuccessDialog isModalOpen={isOpenSuccess} />

      <FailedDialog
        isModalOpen={isOpenFailed}
        onCancel={() => {
          setIsOpenFailed(false);
        }}
        onConfirm={() => {
          setIsOpenFailed(false);
          handleUpdateStatus();
        }}
      />
    </>
  );
};

export default DrawerStatus;
