import React from "react";
import { Button, Modal, Flex } from "antd";
import IconDelete from "../../assets/icon/icon_delete_dialog.js";
import CustomButton from "../Button/CustomButton.js";

const DeleteDialog = ({
  isModalOpen,
  closable,
  title,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  description,
}) => {

  return (
    <>
      <Modal
        title=""
        open={isModalOpen}
        footer={null}
        closable={closable}
        centered
      >
        {/* className="flex flex-col justify-center items-center" */}
        <Flex justify="center" align="center" vertical>
          <IconDelete />
          <span
            style={{
              fontWeight: 600,
              fontSize: 22,
              marginTop: 10,
            }}
          >
            {title}
          </span>
          <span
            style={{
              fontWeight: 500,
              fontSize: 16,
              marginTop: 0,
              color: "#475467",
            }}
          >
            {description}
          </span>
          {/* <div className="flex flex-row items-center justify-center mt-[20px]"></div> */}
          <Flex
            gap="middle"
            justify="center"
            align="center"
            vertical={false}
            style={{ marginTop: 20 }}
          >
            <Button
              //loading={loadingExcel}
              // icon={<FileExcelOutlined />}
              type="primary"
              ghost={true}
              width={135}
              height={32}
              borderRadius="4px"
              style={{
                width:135,
                height:32,
                border: "1px solid #D0D5DD",
                //marginTop: 20,
                color: "#1D273C",
                backgroundColor: "#FFF",
              }}
              onClick={() => {
                onCancel();
              }}
            >
                {cancelText}
              </Button>
            <Button
              type="primary"
              onClick={() => {
                onCancel();
                onConfirm();
                // navigate("/survey-form-list");
              }}
              style={{
                width:135,
                height:32,
                marginTop: 0,
                // marginRight: 10,
                backgroundColor: "#FF4D4F",
                // color: "#ffffff",
                border: "4px",
              }}
              // type="primary"
              >
            {confirmText}
          </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default DeleteDialog;
