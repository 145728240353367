import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const CampaingService = {
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/campaigns/${id}`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },

  search: async function (params, cancel = false) {
    const response = await api.request({
      url: "/campaigns",
      method: "GET",
      params: params,
      signal: cancel ? cancelApiObject[this.search.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },

  updateStatus: async function (data, cancel = false) {
    await api.request({
      url: `/campaigns/status`,
      method: `PATCH`,
      data: data,
      signal: cancel ? cancelApiObject[this.updateStatus.name].handleRequestCancellation().signal : undefined,
    });
  },

  updatePriority: async function (data, cancel = false) {
    await api.request({
      url: `/campaigns/priority`,
      method: `PATCH`,
      data: data,
      signal: cancel ? cancelApiObject[this.updatePriority.name].handleRequestCancellation().signal : undefined,
    });
  },

  updateCaller: async function (data, cancel = false) {
    await api.request({
      url: `/campaigns/caller-address`,
      method: `PATCH`,
      data: data,
      signal: cancel ? cancelApiObject[this.updateCaller.name].handleRequestCancellation().signal : undefined,
    });
  },

  // updateScript: async function (data, cancel = false) {
  //   await api.request({
  //     url: `/campaigns/script`,
  //     method: `PATCH`,
  //     data: data,
  //     signal: cancel ? cancelApiObject[this.updateScript.name].handleRequestCancellation().signal : undefined,
  //   });
  // },

  updateSchedule: async function (data, cancel = false) {
    await api.request({
      url: `/campaigns/schedule`,
      method: `PATCH`,
      data: data,
      signal: cancel ? cancelApiObject[this.updateSchedule.name].handleRequestCancellation().signal : undefined,
    });
  },

  Recycle: async function (data, cancel = false) {
    await api.request({
      url: `/campaigns/recycle`,
      method: `DELETE`,
      data: data,
      signal: cancel ? cancelApiObject[this.updateRecycle.name].handleRequestCancellation().signal : undefined,
    });
  },

};

// defining the cancel API object for CampaingService
const cancelApiObject = defineCancelApiObject(CampaingService);
